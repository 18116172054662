import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import * as styles from './styles.module.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autoplay, Navigation } from 'swiper';

const CompaniesCard = ({
  title,
  headerBackgroundColor,
  companyImages,
  // companyImagesData,
  className,
  isCarousel,
}) => {
  const data = useStaticQuery(graphql`
    query {
      CompanyImages: allFile(
        filter: { relativePath: { regex: "images/placements/Companies/" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}
              width: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const companyImagesData = data.CompanyImages.nodes;

  const CompanyCarousel = () => {
    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);
    const swiperRef = React.useRef(null);

    // const companies = companyImages;

    const settings = {
      slidesPerView: 3,
      breakpoints: {
        768: {
          slidesPerView: 6,
        },
      },
      id: 'projects-carousel',
      loop: true,
      spaceBetween: 20,
      speed: 1000,
      grabCursor: false,
      noSwiping: true,
      preloadImages: true,
      autoplay: false,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    };

    return (
      <div
        className={`relative  my-6 flex   w-full flex-col items-center rounded-3xl bg-v5-neutral-200 ${className}`}
      >
        <div
          className={`${headerBackgroundColor} absolute -top-[14px] left-0 right-0 mx-auto flex h-[30px] w-max items-center rounded-full px-4 py-2 font-manrope text-sm font-bold md:text-base`}
        >
          {title}
        </div>
        <div className="relative w-full py-5 text-neutral-400">
          <Swiper
            ref={swiperRef}
            {...settings}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            modules={[Navigation, Autoplay]}
            className={` w-10/12 py-0`}
          >
            {companyImages.map((companyImage) => {
              const companyImageSource = companyImagesData.find(
                (ele) => ele.name == companyImage.imgUrl,
              );
              const image = getImage(companyImageSource.childImageSharp);

              return (
                <SwiperSlide
                  key={companyImage.title}
                  className="flex h-[80px] items-center "
                >
                  <div
                    className={`flex flex-col items-center justify-between gap-2 rounded-lg  ${styles.companyImages}`}
                  >
                    <div
                    // style={{
                    //   maxHeight: '100%',
                    //   height: '130px',
                    //   width: '80px',
                    // }}
                    >
                      <GatsbyImage
                        loading="lazy"
                        image={image}
                        placeholder="blurred"
                        alt={'Learning'}
                        imgStyle={{ objectFit: 'contain' }}
                        style={{ maxHeight: '130px', maxWidth: '100px' }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div
            onClick={() => swiperRef.current.swiper.slidePrev()}
            ref={navigationPrevRef}
            className="absolute top-1/2 left-5 z-10  -translate-x-1/2 -translate-y-1/2 transform cursor-pointer  hover:opacity-75 sm:left-10"
          >
            <FontAwesomeIcon icon={['fas', 'chevron-left']}></FontAwesomeIcon>
          </div>{' '}
          <div
            onClick={() => swiperRef.current.swiper.slideNext()}
            ref={navigationNextRef}
            className="absolute top-1/2 right-5 z-10 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer hover:opacity-75 sm:right-10"
          >
            <FontAwesomeIcon icon={['fas', 'chevron-right']}></FontAwesomeIcon>
          </div>
        </div>
      </div>
    );
  };

  if (isCarousel) {
    return <CompanyCarousel />;
  }

  return (
    <div
      className={`my-8 flex  w-full flex-col items-center rounded-3xl bg-v5-neutral-200 lg:max-w-[1136px] ${className}`}
    >
      <div
        className={`${headerBackgroundColor} flex h-[44px] -translate-y-[22px] items-center rounded-full px-4 py-2 font-manrope text-sm font-bold md:text-base`}
      >
        {title}
      </div>
      <div>
        <div className="flex w-full flex-wrap items-center justify-center justify-items-center gap-4 pb-4">
          {companyImages.map((companyImage) => {
            const companyImageSource = companyImagesData.find(
              (ele) => ele.name == companyImage.imgUrl,
            );
            const image = getImage(companyImageSource.childImageSharp);

            return (
              <div
                className={`w-1/4 text-center md:w-1/5 ${styles.companyImages}`}
                key={companyImage.title}
              >
                <GatsbyImage
                  loading="lazy"
                  image={image}
                  placeholder="blurred"
                  alt={'Company'}
                  className="w-20 md:w-32"
                  imgStyle={{ objectFit: 'contain' }}
                />
              </div>
            );
          })}
        </div>
        <p className="mb-8 text-center font-manrope text-sm font-extrabold text-v5-neutral-400">
          And More
        </p>
      </div>
    </div>
  );
};

export default CompaniesCard;
