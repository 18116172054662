import React from 'react';

import RawHTML from '@components/Common/RawHTML/index';
import SEO from '@components/Common/SEO/index';
import CompaniesCard from '@components/CompaniesCard';
import FooterV2 from '@components/FooterV2';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import Navigation from '@components/Navigation';
import TestimonialSlider from '@components/TestimonialSlider/index';
import RoundedLayout from '@components/v5/layouts/RoundedLayout/index';
import { LEAD_GEN_HERO } from '@src/constants/LeadGenerationConstants/index';
import useCrioStat from '@src/hooks/useCrioStats';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Testimonials,
  statsV2,
  MNCsCompanyImages,
  SoonicornCompanyImages,
  UnicornCompanyImages,
} from '../../PageData/data.js';
import {
  LEAD_GEN_COMPANIES,
  LEAD_GEN_COMPENSATION,
  LEAD_GEN_LEARNER_STATS,
  LEAD_GEN_PRE_FOOTER,
} from '../../constants/LeadGenerationConstants/index';
import * as styles from './placements.module.scss';
const HIKE_PERCENT_INDEX = 4;

export default function Placements(props) {
  const CompanyImagesData = props.data.CompanyImages.nodes;
  const IconImagesData = props.data.IconImages.nodes;
  const TestimonialImagesData = props.data.TestimonialImages.nodes;
  const { placementStats } = useCrioStat();
  const hikePercent = placementStats[HIKE_PERCENT_INDEX].content; //data coming from strapi.

  const pageData = props.data.strapiPrograms;
  const typeOfProgram = pageData.PrimaryProgramID;
  const typeform = pageData.typeform;

  return (
    <div className="flex justify-center">
      <SEO
        canonicalUrl={'https://crio.do/placements'}
        title={'Placement Stats'}
        description={
          'Helping our learners get the best career outcomes. Find out where Crio grads get placed.'
        }
      ></SEO>
      <div className="relative h-[100vh] w-screen">
        <div className="fixed z-10 w-screen">
          <Navigation hideTopStrip={true} />
        </div>
        <section
          className={`${styles.Hero} relative mt-[60px] flex h-[400px] justify-center pt-8 md:min-h-[460px]`}
        >
          <div className="relative z-[1] mx-4 w-full text-center md:mx-auto">
            <p className="font-rubik text-base font-bold text-v5-green-200 md:text-lg">
              Placement Stats
            </p>
            <h1 className="mb-4 font-manrope text-3xl font-extrabold leading-tight md:text-5xl md:leading-none">
              Helping our Learners
              <br /> Get the Best Career
              <br /> Breakthroughs and
              <br />
              into the Best Companies
            </h1>
            <p className="font-rubik text-base leading-relaxed text-v5-neutral-400 md:text-lg">
              We want to ensure that our learners achieve the best possible
              <br className="hidden md:block" />
              career outcomes and are able to fulfill their professional goals.
            </p>
          </div>
          <div className="absolute -bottom-52 left-1/2 z-[1] grid w-4/5 -translate-x-1/2 grid-cols-2 grid-rows-2 gap-2 md:-bottom-20 md:max-w-[800px] md:grid-cols-4 md:grid-rows-1">
            {statsV2.map((el) => (
              <div
                key={el.title}
                className={`justify flex max-h-[180px] max-w-[180px] flex-col items-center justify-start rounded-xl p-4 py-6 text-center`}
                style={{ background: el.backgroundColor }}
              >
                <h2 className="font-manrope text-4xl">{el.title}</h2>
                <p className="font-rubik text-xs">
                  <RawHTML>{el.subtitle}</RawHTML>
                </p>
              </div>
            ))}
          </div>
          <div
            className={`absolute top-1/2 -left-24 hidden h-[500px] w-[500px] -translate-y-1/2 md:block`}
          >
            <StaticImage
              loading="eager"
              src="../../images/placements/Header-Motif-PieChart.png"
              placeholder="blurred"
              alt="Underline"
              className="h-full w-full"
            />
          </div>
          <div
            className={`absolute bottom-0 right-20 hidden h-[200px] w-[200px] md:block`}
          >
            <StaticImage
              loading="eager"
              src="../../images/placements/Header-Motif-BarGraph.png"
              placeholder="blurred"
              alt="Underline"
              className="h-full w-full"
            />
          </div>
        </section>
        <div className="flex h-[200px] items-center justify-center bg-white">
          <div
            className={`mt-20 hidden justify-center md:flex md:justify-start`}
          >
            <LeadGenerationButton
              type="Apply & Start Free"
              className="w-[300px]"
              buttonLocation={LEAD_GEN_HERO}
            />
          </div>
        </div>
        <section className="flex flex-col items-center bg-white px-4 py-8">
          <SectionHeading
            highlightText={'Recruiters'}
            title={
              <>
                Top Unicorns, Soonicorns, and
                <br /> Global Tech MNCs hire <br className="block md:hidden" />
                from Crio
              </>
            }
            subtitle={
              'Recruiters from top companies and hyper-growth startups love working with Crio grads.'
            }
          />
          <CompaniesCard
            title="Unicorns"
            headerBackgroundColor="bg-v5-lime-200"
            companyImages={UnicornCompanyImages}
            className="mt-12"
          />
          <CompaniesCard
            title="Hyper growth startups & Soonicorns"
            headerBackgroundColor="bg-v5-lime-400"
            companyImages={SoonicornCompanyImages}
          />
          <CompaniesCard
            title="Global tech MNCs"
            headerBackgroundColor="bg-v5-green-100"
            companyImages={MNCsCompanyImages}
          />
          <div className={`flex justify-center md:justify-start`}>
            <LeadGenerationButton
              type="Apply & Start Free"
              className="w-[300px]"
              buttonLocation={LEAD_GEN_COMPANIES}
            />
          </div>
        </section>
        <section className="flex flex-col items-center px-4 py-8">
          <SectionHeading
            highlightText={'Student background'}
            title={
              <>
                Our learners come from diverse
                <br className="hidden md:block" /> backgrounds and experiences
              </>
            }
            subtitle={'Get a comprehensive learning experience.'}
          />
          <div className={`mt-8 hidden w-[700px] md:block`}>
            <StaticImage
              loading="eager"
              src="../../images/placements/RolesStats.png"
              placeholder="blurred"
              alt="Underline"
              className="h-full w-full"
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
          <div className={`mt-8 block w-full md:hidden`}>
            <StaticImage
              loading="eager"
              src="../../images/placements/RolesStats-Mobile.png"
              placeholder="blurred"
              alt="Underline"
              className="h-full w-full"
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
          <div className={`mt-8 flex justify-center md:justify-start`}>
            <LeadGenerationButton
              type="Apply & Start Free"
              className="w-[300px]"
              buttonLocation={LEAD_GEN_LEARNER_STATS}
            />
          </div>
        </section>
        <section className="flex flex-col items-center px-4 py-8">
          <SectionHeading
            highlightText={'Compensation'}
            title={
              <>
                Crio students get an average{' '}
                <span className="text-v5-green-200">
                  salary <br className="hidden md:block" />
                  hike of {hikePercent}
                </span>{' '}
                after graduating from Crio
              </>
            }
            subtitle={
              'Work in great companies with better compensation after your time at Crio.'
            }
          />
          <div className={`mt-8 h-[10%] w-full md:w-[700px]`}>
            <StaticImage
              loading="eager"
              src="../../images/strapi/placement-stats/hikepercent.webp"
              placeholder="blurred"
              alt="Underline"
              className="h-full w-full"
              height={400}
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
          <div className={`mt-8 flex justify-center md:justify-start`}>
            <LeadGenerationButton
              type="Apply & Start Free"
              className="w-[300px]"
              buttonLocation={LEAD_GEN_COMPENSATION}
            />
          </div>
        </section>
        <RoundedLayout roundedBg="bg-v5-green-100" className="bg-v5-green-500">
          {/* <section className="bg-v5-green-500 rounded-b-[60px] md:rounded-b-[100px]"> */}
          <div className="flex flex-col items-center rounded-b-[60px] bg-white px-4 py-8 md:rounded-b-[100px]">
            <SectionHeading
              highlightText={'Success Stories'}
              title={
                <>
                  Hear from some of our learners who
                  <br className="hidden md:block" /> took their careers to new
                  heights
                </>
              }
              subtitle={''}
            />

            <div className="my-4 w-full md:w-4/5 md:max-w-[1100px]">
              <TestimonialSlider
                testimonials={Testimonials}
                companyImagesData={CompanyImagesData}
                testimonialImagesData={TestimonialImagesData}
              />
            </div>
          </div>
          <section className="border-v5-green-500 bg-v5-green-100">
            <div className="flex flex-col items-center rounded-b-[60px] bg-v5-green-500 px-4 py-8 md:rounded-b-[100px]">
              <div className="z-[1] mb-16 mt-8 w-full text-center md:w-3/5">
                <h2 className="text-center font-manrope text-3xl font-extrabold leading-tight text-v5-green-100 md:text-5xl md:leading-none">
                  Gain Real Work
                  <br /> Experience and Crack
                  <br /> Jobs at the Fastest
                  <br /> Growing Companies
                </h2>
                <div className={`mt-8 flex justify-center`}>
                  <LeadGenerationButton
                    type="Apply & Start Free"
                    className="w-[300px]"
                    buttonLocation={LEAD_GEN_PRE_FOOTER}
                  />
                </div>
              </div>
            </div>
          </section>
          {/* </section> */}
        </RoundedLayout>
        <FooterV2 />
      </div>
    </div>
  );
}

const SectionHeading = ({ highlightText, title, subtitle }) => {
  return (
    <div className="relative z-[1] w-full text-center">
      <p className="font-rubik text-base font-bold text-v5-green-200 md:text-lg">
        {highlightText}
      </p>
      <h1 className="mb-4 mt-2 font-manrope text-3xl font-extrabold leading-tight md:text-4xl">
        {title}
      </h1>
      <p className="font-rubik text-base leading-relaxed text-v5-neutral-400 md:text-lg">
        {subtitle}
      </p>
    </div>
  );
};

export const pageQuery = graphql`
  query {
    strapiPrograms(PrimaryProgramID: { eq: "FREE_TRIAL" }) {
      ...strapiProgramsFragment
    }

    CompanyImages: allFile(
      filter: { relativePath: { regex: "images/placements/Companies/" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 400
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }

    TestimonialImages: allFile(
      filter: { relativePath: { regex: "images/placements/Testimonials/" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 300
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }

    IconImages: allFile(
      filter: { relativePath: { regex: "images/placements/Icons/" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }

    learningDataImages: allFile(
      filter: { relativePath: { regex: "images/recruit/Why/" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }

    allCompanyList: allFile(
      filter: { relativePath: { regex: "images/strapi/companiesV2/" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 1000
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;
